<template>
  <div>
    <PageHeader text="Восстановить пароль" />
    <div v-if="loading">Загружаю...</div>

    <div v-else-if="!loading && !maskedLogin" class="red--text">
      {{ errorMessage }}
    </div>

    <div v-else>
      <div>
        Придумайте новый пароль для
        <span class="font-weight-bold">{{ maskedLogin }}</span
        >. Пароль должен быть не короче 8 знаков.
      </div>
      <div class="mw-150">
        <v-text-field
          v-model="password1"
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword1 ? 'text' : 'password'"
          @click:append="showPassword1 = !showPassword1"
          label="Пароль"
          @input="onInput"
        ></v-text-field>
        <v-text-field
          v-model="password2"
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword2 ? 'text' : 'password'"
          @click:append="showPassword2 = !showPassword2"
          label="Повторите пароль"
          @input="onInput"
        ></v-text-field>
      </div>
      <div class="d-flex align-center" style="min-height: 48px">
        <v-btn @click="onSet" color="primary" class="mr-4"
          >Установить пароль</v-btn
        >
        <v-alert v-if="showAlert" dense outlined type="error" class="my-0">
          {{ alertMessage }}
        </v-alert>
      </div>
      <div v-if="showSuccess">
        Успешно! Сейчас перенаправлю вас на страницу входа
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import { setPassword, getMaskedLogin } from '@/lib/requestsAPI'
import { COMPONENT_PATHS, LOGIN } from '@/store/const/path'

export default {
  data() {
    return {
      errorMessage: 'Нельзя восстановить пароль по этой ссылке.',
      token: null,
      loading: false,
      maskedLogin: '',
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
      showAlert: false,
      alertMessage: '',
      showSuccess: false,
    }
  },
  components: { PageHeader },
  created() {
    this.token = this.$route.params.token
    this.loading = true
    getMaskedLogin(this.token)
      .then(res => {
        this.maskedLogin = res
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.errorMessage += ' Сейчас перенаправляю вас на страницу входа.'
          setTimeout(() => this.$router.push(COMPONENT_PATHS[LOGIN].path), 5000)
        }
      })
      .finally(() => (this.loading = false))
  },
  methods: {
    onInput() {
      this.showAlert = false
    },
    validateForm() {
      if (this.password1.length < 8) {
        this.alertMessage = 'Пароль не меньше 8 знаков'
        this.showAlert = true
        return false
      }
      if (this.password1 !== this.password2) {
        this.alertMessage = 'Пароли не совпадают'
        this.showAlert = true
        return false
      }
      return true
    },
    handleChangeError() {
      this.alertMessage = 'Что-то пошло не так'
      this.showAlert = true
    },
    handleChangeSuccess() {
      this.showSuccess = true
      localStorage.clear()
      setTimeout(() => this.$router.push(COMPONENT_PATHS[LOGIN].path), 3000)
    },
    async onSet() {
      if (!this.validateForm()) return
      setPassword(this.token, this.password1)
        .then(() => this.handleChangeSuccess())
        .catch(() => this.handleChangeError())
    },
  },
}
</script>

<style scoped>
.mw-150 {
  max-width: 600px;
}
</style>
